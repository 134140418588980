import _ from 'lodash';
import { ScheduleOverrideDto } from '../../types/employee-type';
import { ShopOnboardingDto } from '../../types/onboarding-types';
import {
  AppChargeBaseDto,
  AppFeaturesDto,
  AppSubscriptionsDto,
  Banner,
  BannerType,
  CouponDto,
  DomainInfoDto,
  EdittingSchedule,
  FullShopDto,
  GoogleCalendarListDto,
  IntegrationDto,
  ScheduleDto,
  ShopDto,
  ShopHolidayDto,
  ShopInfo,
  SmsHistoryDto,
  SmsLogDto,
  ShopPrefs,
  StoreThemeDto,
  SubscriptionUsedTime,
  TotalCapacityLimitDto,
} from '../../types/shop-types';
import settingsSlice from '../slices/settingsSlice';
import {
  BaseRequestParams,
  BaseResponse,
  PaginationResponse,
  appApi,
} from './BaseApi';

export interface SaveShopDataParams extends BaseRequestParams {
  id: number;
  applyBusinessHr?: number;
  lockTimezone?: number;
  timeFormat?: number;
  timezoneId?: number;
  ccAdmin?: number;
  bccAdmin?: number;
  ccStaff?: number;
  bccStaff?: number;
  attachIcs?: number;
}
interface SaveShopDataResponse {
  shopInfo: ShopInfo;
  shopOnboarding: ShopOnboardingDto;
}

export interface SaveShopHolidaysParams extends BaseRequestParams {
  holidays: ShopHolidayDto[];
}
interface SaveShopHolidaysResponse {
  shopHolidays: ShopHolidayDto[];
}

export interface SaveShopSchedulesParams extends BaseRequestParams {
  schedules: EdittingSchedule[];
}
interface SaveShopSchedulesResponse {
  shopSchedules: ScheduleDto[];
}

export interface SaveTotalCapacityLimitParams extends BaseRequestParams {
  limits: TotalCapacityLimitDto[];
}

export interface SaveShopScheduleOverridesParams extends BaseRequestParams {
  overrideSchedules: ScheduleOverrideDto[];
}
interface SaveShopScheduleOverridesResponse {
  scheduleOverrides: ScheduleOverrideDto[];
}

export interface ConnectAppParams extends BaseRequestParams {
  id: number;
  host: string;
}
interface ConnectAppResponse {
  url: string;
}

export interface DisconnectAppParams extends BaseRequestParams {
  id: number;
}
interface DisconnectAppResponse {
  integrations: IntegrationDto[];
}

interface StoreThemeParams extends BaseRequestParams {
  serviceId?: number;
}

interface RequestFeatureParams extends BaseRequestParams {
  featureId?: number;
}

interface CheckCouponParams extends BaseRequestParams {
  coupon: string;
  intervalType?: number;
}

interface RegisterEmailSenderParams extends BaseRequestParams {
  email: string;
  name: string;
}

interface RequestPlanParams extends BaseRequestParams {
  planId: number;
  subsType: number;
  returnPath?: string;
  coupon?: string;
}

interface RequestBuyCreditParams extends BaseRequestParams {
  amount: number;
  returnPath?: string;
}

interface CalendarListParams extends BaseRequestParams {
  id: number;
}

interface GetBannersParams extends BaseRequestParams {
  limit: number;
  page: number;
  types?: BannerType[];
}
interface BaseSmsLogParams extends BaseRequestParams {
  fromTime: number;
  toTime: number;
  status: string[];
  phone: string;
}
interface SmsLogParams extends BaseSmsLogParams {
  limit: number;
  page: number;
}

interface ExportSmsLogParams extends BaseSmsLogParams {
  type?: number;
}

interface UpdateShopPrefsParams extends BaseRequestParams, ShopPrefs {
  locale?: string;
}

export const shopApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getShop: build.query<FullShopDto, BaseRequestParams>({
      query: (arg) => {
        return { url: '/api/meety-shop/current-shop', method: 'GET', arg };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const {
          data: { shopInfo },
        } = await queryFulfilled;

        dispatch(
          settingsSlice.actions.setShopName({ shopName: shopInfo.name })
        );
        dispatch(
          settingsSlice.actions.setWeekStartsOn({
            weekStartsOn: shopInfo.weekStartsOn,
          })
        );
      },
      providesTags: () => {
        return ['all', 'shop'];
      },
    }),

    getBasicShop: build.query<ShopDto, BaseRequestParams>({
      query: (arg) => {
        return { url: '/api/meety-shop/basic-info', method: 'GET', arg };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const {
          data: { shopInfo },
        } = await queryFulfilled;

        dispatch(
          settingsSlice.actions.setShopName({ shopName: shopInfo.name })
        );
        dispatch(
          settingsSlice.actions.setWeekStartsOn({
            weekStartsOn: shopInfo.weekStartsOn,
          })
        );
      },
      providesTags: () => {
        return ['all', 'shop'];
      },
    }),

    saveShopData: build.mutation<SaveShopDataResponse, SaveShopDataParams>({
      query: (arg) => {
        const { id, ...others } = arg;
        return {
          url: `/api/meety-shop/${id}`,
          method: 'PATCH',
          arg: others,
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const {
          data: { shopOnboarding, shopInfo },
        } = await queryFulfilled;
        dispatch(
          shopApi.util.updateQueryData('getShop', {}, (draft) => {
            const onboardings = _.unionBy(
              [shopOnboarding],
              draft.onboardings,
              'id'
            )
              .filter(Boolean)
              .sort((a, b) => a.onboardingTypeId - b.onboardingTypeId);
            Object.assign(draft, { onboardings, shopInfo });
          })
        );
        dispatch(
          shopApi.util.updateQueryData('getBasicShop', {}, (draft) => {
            const onboardings = _.unionBy(
              [shopOnboarding],
              draft.onboardings,
              'id'
            )
              .filter(Boolean)
              .sort((a, b) => a.onboardingTypeId - b.onboardingTypeId);
            Object.assign(draft, { onboardings, shopInfo });
          })
        );
      },
    }),

    saveShopHolidays: build.mutation<ShopHolidayDto[], SaveShopHolidaysParams>({
      query: (arg) => {
        return {
          url: '/api/meety-shop/shop-holidays',
          method: 'POST',
          arg,
        };
      },
      transformResponse: (res: SaveShopHolidaysResponse) => {
        return res.shopHolidays;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          shopApi.util.updateQueryData('getShop', {}, (draft) => {
            Object.assign(draft, { holidays: data });
          })
        );
      },
    }),

    saveShopSchedules: build.mutation<ScheduleDto[], SaveShopSchedulesParams>({
      query: (arg) => {
        return {
          url: '/api/meety-shop/shop-schedules',
          method: 'POST',
          arg,
        };
      },
      transformResponse: (res: SaveShopSchedulesResponse) => {
        return res.shopSchedules;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          shopApi.util.updateQueryData('getShop', {}, (draft) => {
            // const shopSchedules = _.unionBy(data, draft.shopSchedules, 'id');
            Object.assign(draft, { shopSchedules: data });
          })
        );
      },
    }),

    saveShopScheduleOverrides: build.mutation<
      any[],
      SaveShopScheduleOverridesParams
    >({
      query: (arg) => {
        return {
          url: '/api/meety-shop/shop-schedule-overrides',
          method: 'POST',
          arg,
        };
      },
      transformResponse: (res: SaveShopScheduleOverridesResponse) => {
        return res.scheduleOverrides;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          shopApi.util.updateQueryData('getShop', {}, (draft) => {
            Object.assign(draft, { scheduleOverrides: data });
          })
        );
      },
    }),

    connectApp: build.mutation<ConnectAppResponse, ConnectAppParams>({
      query: (arg) => {
        const { id, ...others } = arg;
        return {
          url: `/api/meety-shop/connect-app/${id}`,
          method: 'GET',
          arg: others,
        };
      },
    }),

    syncGGCalendar: build.mutation<BaseResponse<any>, BaseRequestParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/sync-gg-calendar`,
          method: 'POST',
          arg,
        };
      },
    }),
    syncOutlookCalendar: build.mutation<BaseResponse<any>, BaseRequestParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/sync-outlook-calendar`,
          method: 'POST',
          arg,
        };
      },
    }),
    missingPublicHolidays: build.query<ShopHolidayDto[], BaseRequestParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/shop-holidays/missing-public-holidays`,
          method: 'GET',
          arg,
        };
      },
      transformResponse: (res: ShopHolidayDto[]) => {
        for (const d of res) {
          d.sourceType = 'public_holiday';
        }
        return res;
      },
    }),

    disconnectApp: build.mutation<IntegrationDto[], DisconnectAppParams>({
      query: (arg) => {
        const { id, ...others } = arg;
        return {
          url: `/api/meety-shop/disconnect-app/${id}`,
          method: 'GET',
          arg: others,
        };
      },
      transformResponse: (res: DisconnectAppResponse) => {
        return res.integrations;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          shopApi.util.updateQueryData('getShop', {}, (draft) => {
            Object.assign(draft, { integrations: data });
          })
        );
      },
    }),

    getStoreTheme: build.query<StoreThemeDto, StoreThemeParams>({
      query: (arg) => {
        return { url: '/api/meety-shop/store-theme', method: 'GET', arg };
      },
    }),

    getBanners: build.query<PaginationResponse<Banner[]>, GetBannersParams>({
      query: (arg) => {
        return { url: '/api/misc/banners', method: 'GET', arg };
      },
    }),

    requestFeature: build.mutation<AppFeaturesDto, RequestFeatureParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/request-feature`,
          method: 'POST',
          arg,
        };
      },
      invalidatesTags: () => {
        return ['shop'];
      },
    }),

    getCalendarList: build.mutation<GoogleCalendarListDto, CalendarListParams>({
      query: (arg) => {
        const { id, ...others } = arg;
        return {
          url: `/api/meety-shop/calendar-list/${id}`,
          method: 'GET',
          arg: others,
        };
      },
    }),

    getPlanUsedTime: build.query<SubscriptionUsedTime[], BaseRequestParams>({
      query: (arg) => {
        return { url: '/api/meety-shop/plan-used-time', method: 'GET', arg };
      },
    }),

    requestPlan: build.mutation<AppSubscriptionsDto, RequestPlanParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/buy-plan`,
          method: 'POST',
          arg,
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data.planId) {
          dispatch(
            shopApi.util.updateQueryData('getShop', {}, (draft) => {
              draft.shopInfo.subscriptionTypeId = data.planId;
            })
          );
        }
      },
      invalidatesTags: () => {
        return ['shop'];
      },
    }),
    requestBuyCredit: build.mutation<AppChargeBaseDto, RequestBuyCreditParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/buy-sms-credit`,
          method: 'POST',
          arg,
        };
      },
    }),
    checkCoupon: build.mutation<CouponDto, CheckCouponParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/check-coupon`,
          method: 'POST',
          arg,
        };
      },
    }),
    getSmsLog: build.query<
      SmsHistoryDto & { smsLogs: PaginationResponse<SmsLogDto[]> },
      SmsLogParams
    >({
      query: (arg) => {
        return {
          url: `/api/meety-shop/sms-log`,
          method: 'GET',
          arg: arg,
        };
      },
      keepUnusedDataFor: 1,
    }),
    exportSmsLog: build.mutation<Blob, ExportSmsLogParams>({
      query: (arg) => {
        return {
          url: '/api/meety-shop/export-sms-history',
          method: 'GET',
          arg: { isBlob: true, ...arg },
        };
      },
    }),
    getPrefs: build.query<ShopPrefs, BaseRequestParams>({
      query: (arg) => {
        return { url: '/api/meety-shop/shop-prefs', method: 'GET', arg };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(settingsSlice.actions.setLocale({ locale: data.locale }));
        }
      },
    }),
    updatePrefs: build.mutation<ShopPrefs, UpdateShopPrefsParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/shop-prefs`,
          method: 'POST',
          arg,
        };
      },
    }),
    registerEmailSender: build.mutation<any, RegisterEmailSenderParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/register-email`,
          method: 'POST',
          arg,
        };
      },
    }),
    verifyEmailSender: build.mutation<any, RegisterEmailSenderParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/verify-email`,
          method: 'POST',
          arg,
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(
            shopApi.util.updateQueryData('getShop', {}, (draft) => {
              draft.emailSetting = data;
            })
          );
        }
      },
      invalidatesTags: () => {
        return ['shop'];
      },
    }),
    getDomainInfo: build.query<DomainInfoDto, RegisterEmailSenderParams>({
      query: (arg) => {
        return {
          url: `/api/meety-shop/domain-info`,
          method: 'GET',
          arg,
        };
      },
    }),

    saveShopTotalLimit: build.mutation<
      TotalCapacityLimitDto[],
      SaveTotalCapacityLimitParams
    >({
      query: (arg) => {
        return {
          url: '/api/meety-shop/total-limit',
          method: 'POST',
          arg,
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          shopApi.util.updateQueryData('getShop', {}, (draft) => {
            // const shopSchedules = _.unionBy(data, draft.shopSchedules, 'id');
            Object.assign(draft, { totalCapacityLimits: data });
          })
        );
      },
      invalidatesTags: () => {
        return ['shop'];
      },
    }),
  }),
});

export const {
  useGetShopQuery,
  useGetBasicShopQuery,
  useLazyGetShopQuery,
  useLazyGetBasicShopQuery,
  useSaveShopDataMutation,
  useSaveShopHolidaysMutation,
  useSaveShopSchedulesMutation,
  useSaveShopScheduleOverridesMutation,
  useConnectAppMutation,
  useDisconnectAppMutation,
  useLazyMissingPublicHolidaysQuery,
  useGetStoreThemeQuery,
  useSyncGGCalendarMutation,
  useSyncOutlookCalendarMutation,
  useGetBannersQuery,
  useRequestFeatureMutation,
  useGetCalendarListMutation,
  useRequestPlanMutation,
  useRequestBuyCreditMutation,
  useGetPlanUsedTimeQuery,
  useGetSmsLogQuery,
  useExportSmsLogMutation,
  useCheckCouponMutation,
  useUpdatePrefsMutation,
  useRegisterEmailSenderMutation,
  useVerifyEmailSenderMutation,
  useGetDomainInfoQuery,
  useLazyGetDomainInfoQuery,
  useSaveShopTotalLimitMutation,
} = shopApi;
