import '@shopify/polaris/build/esm/styles.css';
import '../styles/global.css';

import { FooterHelp, Frame } from '@shopify/polaris';
import { TranslationDictionary } from '@shopify/react-i18n';
import App, { AppProps } from 'next/app';
import getConfig from 'next/config';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
  AnonymousCrispChat,
  CrispChat,
} from '../components/CrispChat/CrispChat';
import NavigationMenu from '../components/NavigationMenu/NavigationMenu';
import PageFooter from '../components/PageFooter/PageFooter';
import AnalyticsProvider, {
  MixpanelPropertiesUpdater,
} from '../components/providers/AnalyticsProvider';
import GraphQLProvider from '../components/providers/GraphQLProvider';
import I18nProvider from '../components/providers/I18nProvider';
import PolarisProvider from '../components/providers/PolarisProvider';
import RouterProvider from '../components/providers/RouterProvider';
import { IS_PROD } from '../constants/app-const';
import { shopApi } from '../redux/apis/ShopApi';
import { reduxWrapper, reduxWrapperGetInitialAppProps } from '../redux/store';
import { translateLocale } from '../utils/helpers';
import { isServer } from '../utils/is-server';
import Error from './_error';

const theme = {};

const MyApp = ({ Component, ...rest }: AppProps) => {
  const { store, props } = reduxWrapper.useWrappedStore(rest);
  const {
    pageProps,
    shop,
    locale,
    translations,
    pathname,
    isOnboarding,
    isProd,
    err,
  } = props;
  const {
    publicRuntimeConfig: {
      MIXPANEL_KEY,
      CRISP_WEB_ID,
      CLARITY_PROJECT_ID,
      GA_MEASUREMENT_ID,
    },
  } = getConfig();
  const myLocale = store.getState().settings?.locale || locale;
  return (
    <main>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RouterProvider>
            <AnalyticsProvider
              shop={shop}
              pathname={pathname}
              isProd={isProd}
              gaMeasurementId={GA_MEASUREMENT_ID}
              clarityProjectId={CLARITY_PROJECT_ID}
              mixPanelKey={MIXPANEL_KEY}
            >
              <I18nProvider initLocale={myLocale}>
                <PolarisProvider translations={translations}>
                  <Frame
                    logo={{
                      width: 50,
                      contextualSaveBarSource:
                        'https://cdn.shopify.com/s/files/applications/b951a800586a400c245b5d4d91ea5189_512x512.png?1693448134',
                    }}
                  >
                    {err ? (
                      <>
                        <Error
                          statusCode={err?.statusCode ?? -1}
                          message={err?.message}
                          stack={err?.stack}
                        />
                        <AnonymousCrispChat websiteId={CRISP_WEB_ID} />
                      </>
                    ) : (
                      <>
                        {!isOnboarding ? <NavigationMenu /> : null}
                        <MixpanelPropertiesUpdater />
                        <GraphQLProvider>
                          {/* <Header /> */}
                          <CrispChat websiteId={CRISP_WEB_ID} />
                          <Component {...pageProps} />
                          <FooterHelp>
                            <PageFooter />
                          </FooterHelp>
                        </GraphQLProvider>
                      </>
                    )}
                  </Frame>
                </PolarisProvider>
              </I18nProvider>
            </AnalyticsProvider>
          </RouterProvider>
        </Provider>
      </ThemeProvider>
    </main>
  );
};

MyApp.getInitialProps = reduxWrapperGetInitialAppProps(
  async ({ store: { dispatch, getState }, context: appCtx, fetch, shop }) => {
    const {
      ctx: { pathname, query, err, req },
    } = appCtx;
    const { locale: localeFromQuery, charge_id } = query as Record<
      string,
      string
    >;

    if (fetch) {
      if (charge_id) {
        const res = await fetch(`/api/misc/app-subs/${charge_id}?shop=${shop}`);
        // if (res.status < 400) {
        //   await res.json();
        // } else {
        //   console.error(res);
        // }
      }

      // if browser does not support cookie, retrieving settings from server.
      if (!req?.headers.cookie) {
        await Promise.all([
          shopApi.endpoints.getPrefs.initiate({
            _non_serializable_domain: shop,
          })(dispatch, getState, null),
        ]);
        await Promise.all([
          ...shopApi.util.getRunningQueriesThunk()(dispatch, getState, null),
        ]);
      }
    }

    const locale = getState().settings?.locale || localeFromQuery;
    let translations: TranslationDictionary | undefined = undefined;
    if (isServer()) {
      const locales = translateLocale(locale);
      const [myTrans, polarisTrans] = await Promise.all([
        import(`../locales/${locales[0]}.json`).catch(() => {
          return { default: {} };
        }),
        import(`@shopify/polaris/locales/${locales[1]}.json`).catch(() => {
          return { default: {} };
        }),
      ]);
      translations = { ...myTrans.default, ...polarisTrans.default };
    }

    const childrenGip = await App.getInitialProps(appCtx);

    return {
      pageProps: { ...childrenGip.pageProps, shop, locale },
      shop,
      locale,
      translations,
      pathname,
      isOnboarding: pathname === '/onboarding',
      isProd: IS_PROD,
      err,
    };
  }
);

export default MyApp;
