import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Settings } from 'luxon';
import { SettingsSliceType } from '../../types/settings-type';
import { getWeekFirstDay } from '../../utils/datetime';
import { ReduxState } from '../store';

const initialState: SettingsSliceType = {
  locale: undefined,
  shopName: '',
  weekStartsOn: 0,
  liveAgentTime: 0,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<{ locale?: string }>) => {
      if (action.payload.locale) {
        Settings.defaultLocale = action.payload.locale;
      }
      state.locale = action.payload.locale;
    },
    setShopName: (state, action: PayloadAction<{ shopName: string }>) => {
      state.shopName = action.payload.shopName;
    },
    setWeekStartsOn: (
      state,
      action: PayloadAction<{ weekStartsOn: number }>
    ) => {
      state.weekStartsOn = action.payload.weekStartsOn;
    },
    setLiveAgentTime: (
      state,
      action: PayloadAction<{ liveAgentTime: number }>
    ) => {
      state.liveAgentTime = action.payload.liveAgentTime;
    },
    setEnabledAppEmbedBlock: (state, action: PayloadAction<number>) => {
      state.enabledAppEmbedBlock = action.payload;
    },
    addNewServiceIds: (state, action: PayloadAction<number[]>) => {
      state.newServiceIds = state.newServiceIds
        ? [...state.newServiceIds, ...action.payload]
        : action.payload;
    },
    removeNewServiceIds: (state, action: PayloadAction<number[]>) => {
      state.newServiceIds = state.newServiceIds?.filter(
        (id) => !action.payload.includes(id)
      );
    },
    setSettings: (state, action: PayloadAction<Partial<SettingsSliceType>>) => {
      Object.assign(state, action.payload);
      if (action.payload.locale) {
        Settings.defaultLocale = action.payload.locale;
      }
    },
  },
});

export const { setLiveAgentTime } = settingsSlice.actions;

export const selectSettings = (state: ReduxState) => state.settings;
export const selectLocale = (state: ReduxState) =>
  state.settings.locale ?? 'en';
export const selectWeekStartsOn = (state: ReduxState) =>
  state.settings.weekStartsOn || getWeekFirstDay(state.settings.locale);
export const selectLiveAgentTime = (state: ReduxState) =>
  state.settings.liveAgentTime ?? 0;
export const selectEnabledAppEmbedBlock = (state: ReduxState) =>
  state.settings.enabledAppEmbedBlock ?? 0;
export const selectNewServiceIds = (state: ReduxState) =>
  state.settings.newServiceIds ?? [];

export default settingsSlice;
