import { I18n } from '@shopify/react-i18n';
import _ from 'lodash';
import { supportLocales } from '../constants/app-features';
import { DISCOUNT_TYPE } from '../constants/enums';
import { AppFeaturesDto } from '../types/shop-types';

export function downloadFile(blob: Blob | MediaSource, name: string) {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);
  // Create a link element
  const link = document.createElement('a');
  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;
  // Append link to the body
  document.body.appendChild(link);
  link.click();
  // Remove link from body
  document.body.removeChild(link);
}

export function extractProductId(gid: string): string | null {
  const matches = gid.match(/^gid:\/\/shopify\/Product\/(\d*)$/);
  return matches && matches.length > 1 ? matches[1] : null;
}

export function extractProductVariantId(gid: string): string | null {
  const matches = gid.match(/^gid:\/\/shopify\/ProductVariant\/(\d*)$/);
  return matches && matches.length > 1 ? matches[1] : null;
}

export function generateProductVariantId(id: string): string {
  return `gid://shopify/ProductVariant/${id}`;
}

export type TYPE_MONEY = '100,000.00' | '100.000,00';

export const getMoneyFormat = (
  str: string,
  typeMoneyFormat: TYPE_MONEY = '100,000.00'
): string => {
  return dotMoney(str, typeMoneyFormat);
};

export const numberFormat = (n: number, lang = 'vi') => {
  return dotMoney('' + n, lang == 'vi' ? '100.000,00' : '100,000.00');
};

const dotMoney = (str: string, typeMoneyFormat: TYPE_MONEY): string => {
  try {
    let thousandsSeparator = '';
    let decimalSeparator = '.';

    const temp = String(str).split('.');

    if (typeMoneyFormat == '100.000,00') {
      thousandsSeparator = '.';
      decimalSeparator = ',';
    } else if (typeMoneyFormat == '100,000.00') {
      thousandsSeparator = ',';
      decimalSeparator = '.';
    }

    if (temp.length < 2) {
      return String(str)
        .replace(/[^\d-]/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    } else {
      if (temp[1].length > 0) {
        return (
          temp[0]
            .replace(/[^\d-]/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator) +
          decimalSeparator +
          temp[1]
        );
      } else {
        return temp[0]
          .replace(/[^\d-]/g, '')
          .replace(/\B(?=(\\d{3})+(?!\d))/g, thousandsSeparator);
      }
    }
  } catch (error) {
    return str;
  }
};

export function copyToClipboard(text: string): void {
  const boldText = '<strong>' + text + '</strong>';
  const blobHtml = new Blob([boldText], { type: 'text/html' });
  const blobText = new Blob([text], { type: 'text/plain' });
  const data = [
    new ClipboardItem({
      ['text/plain']: blobText,
      ['text/html']: blobHtml,
    }),
  ];
  navigator.clipboard.write(data);
}

/**
 *
 * @param featureId
 * @param subsTypeId
 * @param additionalFeatures
 * @returns
 */
export function hasFeatureEnabled(
  featureId: number,
  subsTypeId: number,
  additionalFeatures: number[]
): boolean {
  return additionalFeatures && additionalFeatures.includes(featureId);
}

export function hasShopFeature(
  appFeatures: number[],
  shopAppFeatures: AppFeaturesDto[]
) {
  const checkFeature = appFeatures.every((feature) =>
    shopAppFeatures?.find(
      (i) =>
        i.appFeatureId === feature &&
        (i.expiredAt === 0 || i.expiredAt > Date.now() / 1000)
    )
  );
  return checkFeature;
}
export const validateEmail = (email: string): boolean => {
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9_]+([\.\-]?\w)*(\+?\w*)?@[0-9a-zA-Z_]+([\.\-]?\w)*(\.[a-zA-Z]{2,10})+$/,
    'gmd'
  );
  return regex.test(email);
};

export const getShortName = (name: string): string => {
  const arr = name?.split(' ');
  if (!arr) {
    return '';
  }
  if (arr.length === 1) {
    return arr[0].substring(0, 3);
  }
  return arr
    .map((x) => {
      return x.trim()[0];
    })
    .join('')
    .substring(0, 3);
};

export const isLocaleSupported = (locale: string): boolean => {
  locale = locale || 'en';
  const arr = locale.split('-');
  if (arr.length > 1 && arr[0].toLowerCase() == arr[1].toLowerCase()) {
    locale = arr[0];
  }
  return supportLocales.includes(locale);
};

export const translateLocale = (locale: string): string[] => {
  locale = locale || 'en';
  const arr = locale.split('-');
  if (arr.length > 1 && arr[0].toLowerCase() == arr[1].toLowerCase()) {
    locale = arr[0];
  }
  let sLocale = locale;
  if (locale == 'vi-VN') {
    locale = 'vi';
    sLocale = 'vi';
  }
  if (locale == 'pt') {
    locale = 'pt-PT';
    sLocale = 'pt-PT';
  }
  if (!supportLocales.includes(locale)) {
    locale = 'en';
    sLocale = 'en';
  }

  return [locale, sLocale];
};

export const getIdByGraphql = (graphqlId: string | null | undefined) => {
  if (!graphqlId || !graphqlId.includes('gid://shopify')) {
    return graphqlId;
  }
  //   return graphqlId.split('/')[4];
  const data = graphqlId.split('/');
  return data[data.length - 1];
};

export const trimString = (str: string, length: number): string => {
  if (!str || str.length <= length) {
    return str;
  }
  const half = ~~((length - 3) / 2);
  return `${str.substring(0, half)}...${str.substring(str.length - half)}`;
};

export const enumToArray = (obj: unknown) => {
  return Object.keys(obj)
    .filter((x) => isNaN(Number(x)))
    .map((x) => [x, obj[x]]);
};

export const getKeyOfEnum = (
  obj: unknown,
  value: string | number,
  toLowerCase?: boolean
) => {
  const key = Object.keys(obj)
    .filter((x) => isNaN(Number(x)))
    .find((x) => {
      return obj[x] === value;
    });
  if (toLowerCase) {
    return key?.toLowerCase();
  }
  return key;
};

export const getDiscountType = (
  type: DISCOUNT_TYPE,
  value: number,
  i18n: I18n,
  currencyCode: string | undefined
) => {
  const currency = i18n.formatCurrency(value || 0, {
    currency: currencyCode || 'usd',
  });
  switch (type) {
    case DISCOUNT_TYPE.percent:
      return `${value}% ${i18n.translate('SubscriptionPlan.off')}`;
    case DISCOUNT_TYPE.amount:
      return `${currency} ${i18n.translate('SubscriptionPlan.off')}`;
    case DISCOUNT_TYPE.fixed:
      return `${currency}`;
  }
};

export function isEqual(
  value: unknown,
  other: unknown,
  excludes: (string | number)[]
) {
  return _.isEqualWith(value, other, (v, o, k: string) => {
    if (k === undefined) {
      return undefined;
    }
    if (excludes?.indexOf(k) >= 0) {
      return true;
    }
    return _.isEqual(v, o);
  });
}

export function shortenText(text: string, maxLength = 30) {
  if (!text || text.length <= maxLength) {
    return text;
  }
  const words = text.split(' ');
  let firstWord = words[0];
  let lastWord = words[words.length - 1];
  if (words.length == 1) {
    lastWord = '';
    firstWord = firstWord.substring(0, maxLength - 3);
  } else {
    const maxPartLength = (maxLength - 4) / 2;
    let index = 0;
    while (firstWord.length < maxLength / 2 && index < words.length - 2) {
      index++;
      firstWord += ' ' + words[index];
    }
    if (firstWord.length > maxPartLength) {
      firstWord = firstWord.substring(0, maxPartLength);
    }
    let index2 = words.length - 1;
    while (lastWord.length < maxLength / 2 && index < index2) {
      index2--;
      lastWord = words[index2] + ' ' + lastWord;
    }
    if (lastWord.length > maxPartLength) {
      lastWord = lastWord.substring(lastWord.length - maxPartLength);
    }
  }

  return firstWord + ' ... ' + lastWord;
}

export function isDevStore(shopfyPlan: string | undefined): boolean {
  const DevPlansToUseEnterprisePlans = ['Development'];
  const result =
    shopfyPlan &&
    shopfyPlan.length > 0 &&
    DevPlansToUseEnterprisePlans.includes(shopfyPlan);
  return !!result;
}

export const convertDiscountToPrice = (
  type: DISCOUNT_TYPE,
  price: number,
  discountValue: number,
  quantity: number
) => {
  const valuePercent = price - (price * discountValue) / 100;
  const valueAmount = price - discountValue;
  switch (type) {
    case DISCOUNT_TYPE.percent:
      return (valuePercent < 0 ? 0 : valuePercent) * quantity;
    case DISCOUNT_TYPE.amount:
      return (valueAmount < 0 ? 0 : valueAmount) * quantity;
    case DISCOUNT_TYPE.fixed:
    default:
      return discountValue * quantity;
  }
};
