export const IS_DEV =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const IS_PROD =
  process.env.NODE_ENV && process.env.NODE_ENV === 'production';

export const ITEMS_PER_PAGE = 30;

export const CHANGELOGS_PER_PAGE = 10;

export const PRODUCTS_PER_PAGE = 10;

export const PRODUCTS_PER_PAGE_ONBOARDING = 2;

export const DASHBOARD_ITEMS_PER_PAGE = 10;

export const SERVICES_PER_PAGE = 10;

export const ANALYTICS_PER_PAGE = 10;

export const BOOKINGS_PER_PAGE = 10;

export const SUBS_BOOKINGS_PER_PAGE = 6;

export const FULFILLED_BOOKINGS_PER_PAGE = 7;

export const STAFFS_PER_PAGE = 10;

export const WIDGETS_PER_PAGE = 10;

export const INPUT_DELAY = 300;

export const SMS_RATES_PER_PAGE = 30;

export const SERVICE_COLORS = [
  '#008DFF',
  '#A964F7',
  '#06C167',
  '#F83446',
  '#FC823A',
  '#F6BC2F',
] as const;

export const PAGES_FOR_MORE_BTN = 5;

export const HOLIDAY_DATE_FORMAT = 'yyyy-MM-dd';
export const HOLIDAY_DATE_FORMAT_LUXON = 'DDD'; //May 27, 2024
export const HOLIDAY_DATE_FORMAT_LUXON_SHORT = 'DD';

export const dayOfWeek = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};
export const EVERYDAY = -1;
export const WEEKDAYS = -2;
export const DOW_EVERYDAY = [0, 1, 2, 3, 4, 5, 6];
export const DOW_EXTRA = [EVERYDAY, WEEKDAYS, 0, 1, 2, 3, 4, 5, 6];
export const DOW_WEEKDAYS = [1, 2, 3, 4, 5];

export const MEETY_APP_LISTING = 'http://shopify.pxf.io/anqGeY';

export const MEETY_APP_PROXY_HANDLE = IS_PROD
  ? '/apps/meety-appointment-booking'
  : IS_DEV
  ? '/apps/meety-development'
  : '/apps/meety-staging';

export const exportType = {
  0: 'csv',
  1: 'xlsx',
};

export const BOOKING_CANCLATION_ENABLED = false;

export const REFETCH_THEME_INFO_DURATION = 10 * 60 * 1000; // 10 minutes

export const MEETY_WEBSITE_CONTACT = 'https://meetyapp.io/docs/about/contact/';

export const PARAM_LINK_WHITELIST = [
  '[Customer portal]',
  '[Product image]',
  '[Variant image]',
];

export const MAX_PREPAID = 20;

export const WIDGET_LANGUAGES = [
  {
    locale: 'af',
    name: 'Afrikaans',
    localName: 'Afrikaans',
  },
  {
    locale: 'ak',
    name: 'Akan',
    localName: 'Akana',
  },
  {
    locale: 'am',
    name: 'Amharic',
    localName: 'አማርኛ',
  },
  {
    locale: 'ar',
    name: 'Arabic',
    localName: 'العربية',
  },
  {
    locale: 'as',
    name: 'Assamese',
    localName: 'অসমীয়া',
  },
  {
    locale: 'az',
    name: 'Azerbaijani',
    localName: 'Azərbaycanca',
  },
  {
    locale: 'be',
    name: 'Belarusian',
    localName: 'Беларуская',
  },
  {
    locale: 'bg-BG',
    name: 'Bulgarian (Bulgaria)',
    localName: 'Български (BG)',
  },
  {
    locale: 'bm',
    name: 'Bambara',
    localName: 'Bamanankan',
  },
  {
    locale: 'bn',
    name: 'Bangla',
    localName: 'বাংলা',
  },
  {
    locale: 'bo',
    name: 'Tibetan',
    localName: 'བོད་ཡིག / Bod skad',
  },
  {
    locale: 'br',
    name: 'Breton',
    localName: 'Brezhoneg',
  },
  {
    locale: 'bs',
    name: 'Bosnian',
    localName: 'Bosanski',
  },
  {
    locale: 'ca',
    name: 'Catalan',
    localName: 'Català',
  },
  {
    locale: 'ce',
    name: 'Chechen',
    localName: 'Нохчийн',
  },
  {
    locale: 'ckb',
    name: 'Central Kurdish',
    localName: 'کوردی',
  },
  {
    locale: 'cs',
    name: 'Czech',
    localName: 'Česky',
  },
  {
    locale: 'cu',
    name: 'Church Slavic',
    localName: 'словѣньскъ / slověnĭskŭ',
  },
  {
    locale: 'cy',
    name: 'Welsh',
    localName: 'Cymraeg',
  },
  {
    locale: 'da',
    name: 'Danish',
    localName: 'Dansk',
  },
  {
    locale: 'de',
    name: 'German',
    localName: 'Deutsch',
  },
  {
    locale: 'dz',
    name: 'Dzongkha',
    localName: 'རྫོང་ཁ',
  },
  {
    locale: 'ee',
    name: 'Ewe',
    localName: 'Ɛʋɛ',
  },
  {
    locale: 'el',
    name: 'Greek',
    localName: 'Ελληνικά',
  },
  {
    locale: 'en',
    name: 'English',
    localName: 'English',
  },
  {
    locale: 'eo',
    name: 'Esperanto',
    localName: 'Esperanto',
  },
  {
    locale: 'es',
    name: 'Spanish',
    localName: 'Español',
  },
  {
    locale: 'et',
    name: 'Estonian',
    localName: 'Eesti',
  },
  {
    locale: 'eu',
    name: 'Basque',
    localName: 'Euskara',
  },
  {
    locale: 'fa',
    name: 'Persian',
    localName: 'فارسی',
  },
  {
    locale: 'ff',
    name: 'Fulah',
    localName: 'Fulfulde',
  },
  {
    locale: 'fi',
    name: 'Finnish',
    localName: 'Suomi',
  },
  {
    locale: 'fil',
    name: 'Filipino',
    localName: 'Filipino',
  },
  {
    locale: 'fo',
    name: 'Faroese',
    localName: 'Føroyskt',
  },
  {
    locale: 'fr',
    name: 'French',
    localName: 'Français',
  },
  {
    locale: 'fy',
    name: 'Western Frisian',
    localName: 'Frysk',
  },
  {
    locale: 'ga',
    name: 'Irish',
    localName: 'Gaeilge',
  },
  {
    locale: 'gd',
    name: 'Scottish Gaelic',
    localName: 'Gàidhlig',
  },
  {
    locale: 'gl',
    name: 'Galician',
    localName: 'Galego',
  },
  {
    locale: 'gu',
    name: 'Gujarati',
    localName: 'ગુજરાતી',
  },
  {
    locale: 'gv',
    name: 'Manx',
    localName: 'Gaelg',
  },
  {
    locale: 'ha',
    name: 'Hausa',
    localName: 'هَوُسَ',
  },
  {
    locale: 'he-IL',
    name: 'Hebrew (Israel)',
    localName: 'עברית (IL)',
  },
  {
    locale: 'hi',
    name: 'Hindi',
    localName: 'हिन्दी',
  },
  {
    locale: 'hr-HR',
    name: 'Croatian (Croatia)',
    localName: 'Hrvatski (HR)',
  },
  {
    locale: 'hu',
    name: 'Hungarian',
    localName: 'Magyar',
  },
  {
    locale: 'hy',
    name: 'Armenian',
    localName: 'Հայերեն',
  },
  {
    locale: 'ia',
    name: 'Interlingua',
    localName: 'Interlingua',
  },
  {
    locale: 'id',
    name: 'Indonesian',
    localName: 'Bahasa Indonesia',
  },
  {
    locale: 'ig',
    name: 'Igbo',
    localName: 'Igbo',
  },
  {
    locale: 'ii',
    name: 'Sichuan Yi',
    localName: 'ꆇꉙ / 四川彝语',
  },
  {
    locale: 'is',
    name: 'Icelandic',
    localName: 'Íslenska',
  },
  {
    locale: 'it',
    name: 'Italian',
    localName: 'Italiano',
  },
  {
    locale: 'ja',
    name: 'Japanese',
    localName: '日本語',
  },
  {
    locale: 'jv',
    name: 'Javanese',
    localName: 'Basa Jawa',
  },
  {
    locale: 'ka',
    name: 'Georgian',
    localName: 'ქართული',
  },
  {
    locale: 'ki',
    name: 'Kikuyu',
    localName: 'Gĩkũyũ',
  },
  {
    locale: 'kk',
    name: 'Kazakh',
    localName: 'Қазақша',
  },
  {
    locale: 'kl',
    name: 'Kalaallisut',
    localName: 'Kalaallisut',
  },
  {
    locale: 'km',
    name: 'Khmer',
    localName: 'ភាសាខ្មែរ',
  },
  {
    locale: 'kn',
    name: 'Kannada',
    localName: 'ಕನ್ನಡ',
  },
  {
    locale: 'ko',
    name: 'Korean',
    localName: '한국어',
  },
  {
    locale: 'ks',
    name: 'Kashmiri',
    localName: 'कॉशुर / کٲشُر',
  },
  {
    locale: 'ku',
    name: 'Kurdish',
    localName: 'Kurdî',
  },
  {
    locale: 'kw',
    name: 'Cornish',
    localName: 'Kernewek',
  },
  {
    locale: 'ky',
    name: 'Kyrgyz',
    localName: 'Kırgızca / Кыргызча',
  },
  {
    locale: 'la',
    name: 'Latin',
    localName: 'Latina',
  },
  {
    locale: 'lb',
    name: 'Luxembourgish',
    localName: 'Lëtzebuergesch',
  },
  {
    locale: 'lg',
    name: 'Ganda',
    localName: 'Luganda',
  },
  {
    locale: 'ln',
    name: 'Lingala',
    localName: 'Lingála',
  },
  {
    locale: 'lo',
    name: 'Lao',
    localName: 'ລາວ',
  },
  {
    locale: 'lt-LT',
    name: 'Lithuanian (Lithuania)',
    localName: 'Lietuvių (LT)',
  },
  {
    locale: 'lu',
    name: 'Luba-Katanga',
    localName: 'Luba-Katanga',
  },
  {
    locale: 'lv',
    name: 'Latvian',
    localName: 'Latviešu',
  },
  {
    locale: 'mg',
    name: 'Malagasy',
    localName: 'Malagasy',
  },
  {
    locale: 'mi',
    name: 'Māori',
    localName: 'Māori',
  },
  {
    locale: 'mk',
    name: 'Macedonian',
    localName: 'Македонски',
  },
  {
    locale: 'ml',
    name: 'Malayalam',
    localName: 'മലയാളം',
  },
  {
    locale: 'mn',
    name: 'Mongolian',
    localName: 'Монгол',
  },
  {
    locale: 'mo',
    name: 'Moldavian',
    localName: 'Moldovenească',
  },
  {
    locale: 'mr',
    name: 'Marathi',
    localName: 'मराठी',
  },
  {
    locale: 'ms',
    name: 'Malay',
    localName: 'Bahasa Melayu',
  },
  {
    locale: 'mt',
    name: 'Maltese',
    localName: 'bil-Malti',
  },
  {
    locale: 'my',
    name: 'Burmese',
    localName: 'Myanmasa',
  },
  {
    locale: 'nb',
    name: 'Norwegian (Bokmål)',
    localName: 'Norsk (bokmål)',
  },
  {
    locale: 'nd',
    name: 'North Ndebele',
    localName: 'Sindebele',
  },
  {
    locale: 'ne',
    name: 'Nepali',
    localName: 'नेपाली',
  },
  {
    locale: 'nl',
    name: 'Dutch',
    localName: 'Nederlands',
  },
  {
    locale: 'nn',
    name: 'Norwegian Nynorsk',
    localName: 'Norsk (nynorsk)',
  },
  {
    locale: 'no',
    name: 'Norwegian',
    localName: 'Norsk (bokmål / riksmål)',
  },
  {
    locale: 'om',
    name: 'Oromo',
    localName: 'Oromoo',
  },
  {
    locale: 'or',
    name: 'Odia',
    localName: 'ଓଡ଼ିଆ',
  },
  {
    locale: 'os',
    name: 'Ossetic',
    localName: 'Иронау',
  },
  {
    locale: 'pa',
    name: 'Punjabi',
    localName: 'ਪੰਜਾਬੀ / پنجابی',
  },
  {
    locale: 'pl',
    name: 'Polish',
    localName: 'Polski',
  },
  {
    locale: 'ps',
    name: 'Pashto',
    localName: 'پښتو',
  },
  {
    locale: 'pt',
    name: 'Portuguese',
    localName: 'Português',
  },
  {
    locale: 'pt-BR',
    name: 'Portuguese (Brazil)',
    localName: 'Português (BR)',
  },
  {
    locale: 'pt-PT',
    name: 'Portuguese (Portugal)',
    localName: 'Português (PT)',
  },
  {
    locale: 'qu',
    name: 'Quechua',
    localName: 'Runa Simi',
  },
  {
    locale: 'rm',
    name: 'Romansh',
    localName: 'Rumantsch',
  },
  {
    locale: 'rn',
    name: 'Rundi',
    localName: 'Kirundi',
  },
  {
    locale: 'ro-RO',
    name: 'Romanian (Romania)',
    localName: 'Română (RO)',
  },
  {
    locale: 'ru',
    name: 'Russian',
    localName: 'Русский',
  },
  {
    locale: 'rw',
    name: 'Kinyarwanda',
    localName: 'Kinyarwandi',
  },
  {
    locale: 'sa',
    name: 'Sanskrit',
    localName: 'संस्कृतम्',
  },
  {
    locale: 'sc',
    name: 'Sardinian',
    localName: 'Sardu',
  },
  {
    locale: 'sd',
    name: 'Sindhi',
    localName: 'सिंधी / سنڌي',
  },
  {
    locale: 'se',
    name: 'Northern Sami',
    localName: 'Davvisámegiella',
  },
  {
    locale: 'sg',
    name: 'Sango',
    localName: 'Sängö',
  },
  {
    locale: 'sh',
    name: 'Serbo-Croatian',
    localName: 'Srpskohrvatski / Српскохрватски',
  },
  {
    locale: 'si',
    name: 'Sinhala',
    localName: 'සිංහල',
  },
  {
    locale: 'sk-SK',
    name: 'Slovak (Slovakia)',
    localName: 'Slovenčina (SK)',
  },
  {
    locale: 'sl',
    name: 'Slovenian',
    localName: 'Slovenščina',
  },
  {
    locale: 'sn',
    name: 'Shona',
    name1: 'Shona',
    localName: 'chiShona',
  },
  {
    locale: 'so',
    name: 'Somali',
    localName: 'Soomaaliga',
  },
  {
    locale: 'sq',
    name: 'Albanian',
    localName: 'Shqip',
  },
  {
    locale: 'sr',
    name: 'Serbian',
    localName: 'Српски',
  },
  {
    locale: 'su',
    name: 'Sundanese',
    localName: 'Basa Sunda',
  },
  {
    locale: 'sv',
    name: 'Swedish',
    localName: 'Svenska',
  },
  {
    locale: 'sw',
    name: 'Swahili',
    localName: 'Kiswahili',
  },
  {
    locale: 'ta',
    name: 'Tamil',
    localName: 'தமிழ்',
  },
  {
    locale: 'te',
    name: 'Telugu',
    localName: 'తెలుగు',
  },
  {
    locale: 'tg',
    name: 'Tajik',
    localName: 'Тоҷикӣ',
  },
  {
    locale: 'th',
    name: 'Thai',
    localName: 'ไทย',
  },
  {
    locale: 'ti',
    name: 'Tigrinya',
    localName: 'ትግርኛ',
  },
  {
    locale: 'tk',
    name: 'Turkmen',
    localName: 'Туркмен',
  },
  {
    locale: 'to',
    name: 'Tongan',
    localName: 'Lea Faka-Tonga',
  },
  {
    locale: 'tr',
    name: 'Turkish',
    localName: 'Türkçe',
  },
  {
    locale: 'tt',
    name: 'Tatar',
    localName: 'Tatarça',
  },
  {
    locale: 'ug',
    name: 'Uyghur',
    localName: 'Uyƣurqə',
  },
  {
    locale: 'uk',
    name: 'Ukrainian',
    localName: 'Українська',
  },
  {
    locale: 'ur',
    name: 'Urdu',
    localName: 'اردو',
  },
  {
    locale: 'uz',
    name: 'Uzbek',
    localName: 'Ўзбек',
  },
  {
    locale: 'vi',
    name: 'Vietnamese',
    localName: 'Tiếng Việt',
  },
  {
    locale: 'vo',
    name: 'Volapük',
    localName: 'Volapük',
  },
  {
    locale: 'wo',
    name: 'Wolof',
    localName: 'Wollof',
  },
  {
    locale: 'xh',
    name: 'Xhosa',
    localName: 'isiXhosa',
  },
  {
    locale: 'yi',
    name: 'Yiddish',
    localName: 'ייִדיש',
  },
  {
    locale: 'yo',
    name: 'Yoruba',
    localName: 'Yorùbá',
  },
  {
    locale: 'zh',
    name: 'Chinese',
    localName: '中文',
  },
  {
    locale: 'zh-CN',
    name: 'Chinese (Simplified)',
    localName: '简体中文',
  },
  {
    locale: 'zh-TW',
    name: 'Chinese (Traditional)',
    localName: '繁體中文',
  },
  {
    locale: 'zu',
    name: 'Zulu',
    localName: 'isiZulu',
  },
];
