import { JwtPayload, decode } from 'jsonwebtoken';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const SHOPIFY_PARAMS = [
  'embedded',
  'hmac',
  'host',
  'locale',
  'session',
  'shop',
  'id_token',
];

interface RouterContextValue {
  pathname: string;
  asPath: string;
  query: ParsedUrlQuery;
  push: (url: string, shallow?: boolean) => Promise<void>;
  replace: (url: string, shallow?: boolean) => Promise<void>;
  back: (fallback: string) => void;
}

export const RouterContext = createContext<RouterContextValue>({
  pathname: '',
  asPath: '',
  query: undefined,
  push: function (_url: string, _shallow?: boolean): Promise<void> {
    throw new Error('Function not implemented.');
  },
  replace: function (_url: string, _shallow?: boolean): Promise<void> {
    throw new Error('Function not implemented.');
  },
  back: function (_fallback: string): void {
    throw new Error('Function not implemented.');
  },
});

export default function RouterProvider({ children }: PropsWithChildren) {
  const nextRouter = useRouter();

  const [isWithinPage, setIsWithinPage] = useState(false);
  const isInitialLoad = useRef(true);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }

    setIsWithinPage(true);
    return () => setIsWithinPage(false);
  }, [nextRouter.pathname]);

  useEffect(() => {
    const onRouteChangeStart = (url: string, { shallow }) => {
      shopify.loading(true);
    };
    const onRouteChangeComplete = (url: string, { shallow }) => {
      shopify.loading(false);
    };
    const onRouteChangeError = (error: Error) => {
      //
    };

    nextRouter.events?.on('routeChangeStart', onRouteChangeStart);
    nextRouter.events?.on('routeChangeComplete', onRouteChangeComplete);
    nextRouter.events?.on('routeChangeError', onRouteChangeError);
    return () => {
      nextRouter.events?.off('routeChangeStart', onRouteChangeStart);
      nextRouter.events?.off('routeChangeComplete', onRouteChangeComplete);
      nextRouter.events?.off('routeChangeError', onRouteChangeError);
    };
  }, [nextRouter.events]);

  const getUrl = useCallback(
    async (url: string) => {
      const hasDomain = url.startsWith('https://') || url.startsWith('http://');
      const aURL = new URL(
        hasDomain
          ? url
          : `https://fake.domain${url.startsWith('/') ? url : `/${url}`}`
      );
      const { origin, pathname, searchParams, hash } = aURL;

      SHOPIFY_PARAMS.forEach((param) => {
        const value = nextRouter.query[param];
        if (value) {
          searchParams.set(
            param,
            Array.isArray(value) ? value.join(',') : value
          );
        }
      });

      const token = await shopify.idToken();
      if (token) {
        searchParams.set('id_token', token);
        if (!searchParams.has('shop')) {
          const decoded = decode(token) as JwtPayload;
          searchParams.set('shop', decoded['dest'].replace('https://', ''));
        }
      }

      return hasDomain
        ? `${origin}${pathname}?${searchParams.toString()}${hash}`
        : `${pathname}?${searchParams.toString()}${hash}`;
    },
    [nextRouter.query]
  );

  const trimUrl = useCallback((url: string, returnPathnameOnly?: boolean) => {
    const hasDomain = url.startsWith('https://') || url.startsWith('http://');
    const aURL = new URL(
      hasDomain
        ? url
        : `https://fake.domain${url.startsWith('/') ? url : `/${url}`}`
    );
    const { origin, pathname, searchParams, hash } = aURL;

    if (returnPathnameOnly) {
      return hasDomain ? `${origin}${pathname}` : pathname;
    }

    SHOPIFY_PARAMS.forEach((param) => searchParams.delete(param));

    return hasDomain
      ? `${origin}${pathname}?${searchParams.toString()}${hash}`
      : `${pathname}?${searchParams.toString()}${hash}`;
  }, []);

  const push = useCallback(
    async (url: string, shallow?: boolean) => {
      const destination = await getUrl(url);
      await nextRouter.push(destination, destination, { shallow });
    },
    [getUrl, nextRouter]
  );

  const replace = useCallback(
    async (url: string, shallow?: boolean) => {
      const destination = await getUrl(url);
      await nextRouter.replace(destination, destination, { shallow });
    },
    [getUrl, nextRouter]
  );

  const back = useCallback(
    (fallback?: string) => {
      if (isWithinPage) {
        nextRouter.back();
      } else {
        replace(fallback);
      }
    },
    [isWithinPage, nextRouter, replace]
  );

  const contextValue = useMemo(() => {
    return {
      pathname: trimUrl(nextRouter.asPath, true),
      asPath: trimUrl(nextRouter.asPath),
      query: nextRouter.query,
      push,
      replace,
      back,
    };
  }, [back, nextRouter.asPath, nextRouter.query, push, replace, trimUrl]);

  return (
    <RouterContext.Provider value={contextValue}>
      {children}
    </RouterContext.Provider>
  );
}
