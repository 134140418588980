import { JwtPayload, decode } from 'jsonwebtoken';
import { ErrorResponse } from '../redux/apis/BaseApi';
import { isServer } from './is-server';

const LATENCY = 5;

export function createAuthenticatedFetch(
  idToken: string
): [typeof fetch | undefined, number, string | undefined, string] {
  let authenticatedFetch: typeof fetch | undefined = undefined;
  let exp = 0;
  let shop: string | undefined = undefined;
  let sub = '0';

  if (idToken) {
    try {
      const payload = decode(idToken) as JwtPayload & { dest?: string };
      exp = payload.exp || 0;
      shop = payload.dest?.replace('https://', '');
      sub = payload.sub || '0';

      if (exp <= Date.now() / 1000 - LATENCY) {
        return [authenticatedFetch, exp, shop, sub];
      }
    } catch (error) {
      console.log(`Failed to parse id_token='${idToken}': ${error}`);
      return [authenticatedFetch, exp, shop, sub];
    }

    if (isServer()) {
      authenticatedFetch = async (
        uri: RequestInfo | URL,
        options?: RequestInit
      ) => {
        const response = await fetch(`${process.env.API_URL}${uri}`, {
          ...options,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        if (
          response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') ===
          '1'
        ) {
          return Promise.reject({
            statusCode: 403,
            errorCode: 403,
            message: 'Unauthorized.',
          } as ErrorResponse);
        }

        return response;
      };
    }
  }

  return [authenticatedFetch, exp, shop, sub];
}
