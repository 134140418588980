import { NavMenu } from '@shopify/app-bridge-react';
import { useI18n } from '@shopify/react-i18n';
import getConfig from 'next/config';
import { useMemo } from 'react';
import { navigationLinksConstants } from '../../constants/options-const';
import useRouter from '../../hooks/useRouter';

export default function NavigationMenu() {
  const [i18n] = useI18n();
  const router = useRouter();

  const navigationLinks = useMemo(() => {
    const { publicRuntimeConfig } = getConfig();
    return [
      ...navigationLinksConstants.map((x) => {
        return { ...x, label: i18n.translate(x.label) };
      }),
      publicRuntimeConfig.DEV_MENU_ENABLED
        ? { destination: '/dev', label: 'Dev' }
        : null,
    ]
      .filter(Boolean)
      .map((item) => {
        // key should be related to pathname so that it forces app-bridge to render the side menu properly whenever we push/replace route.
        const key = item.destination + router.pathname;
        return (
          <a key={key} href={item.destination} rel={item.rel}>
            {item.label}
          </a>
        );
      });
  }, [i18n, router.pathname]);

  return (
    // This menu is rendered by Shopify App Bridge no matter its visibility.
    // We set display none here to prevent CLS.
    <div style={{ display: 'none' }}>
      <NavMenu>{navigationLinks}</NavMenu>
    </div>
  );
}
