import {
  BaseQueryFn,
  createApi,
  defaultSerializeQueryArgs,
} from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';
import popupSlice from '../slices/popupSlice';

export interface BaseRequestParams {
  _non_serializable_domain?: string;
  isBlob?: boolean;
}

export interface ErrorResponse {
  statusCode: number;
  errorCode: number;
  message: string;
}
function isErrorResponse(instance: unknown): instance is ErrorResponse {
  return (
    typeof instance === 'object' &&
    'statusCode' in instance &&
    'errorCode' in instance &&
    'message' in instance
  );
}

export type BaseResponse<T = any> = {
  body: T;
  message: string;
};

export type BodyResponse<T = any> = {
  body: T;
};

export type DataResponse<T = any> = {
  data: T;
};

export type PaginationResponse<T = any> = {
  body: T;
  meta: Meta;
};

export type Meta = {
  pagination: Pagination;
};

export type Pagination = {
  currentPage: number;
  limit: number;
  total: number;
  totalPages: number;
};

export class AuthenticatedFetch {
  private static _fetchByDomain = new Map<
    string,
    {
      fetch:
        | ((uri: RequestInfo, options?: RequestInit) => Promise<Response>)
        | undefined;
      exp: number;
    }
  >();

  static set(
    domain: string,
    fetch:
      | ((uri: RequestInfo, options?: RequestInit) => Promise<Response>)
      | undefined,
    exp: number
  ) {
    // remove all expired entries
    const now = Date.now() / 1000;
    const entries = this._fetchByDomain.entries();
    for (const entry of entries) {
      if (entry[1].exp < now) {
        this._fetchByDomain.delete(entry[0]);
      }
    }

    this._fetchByDomain.set(domain, {
      fetch,
      exp,
    });
  }

  static get(
    domain: string | undefined
  ): (uri: RequestInfo, options?: RequestInit) => Promise<Response> {
    const afetch = domain ? this._fetchByDomain.get(domain)?.fetch : undefined;
    return (
      afetch ??
      (async (uri: RequestInfo, options?: RequestInit) => {
        const response = await fetch(uri, options);

        if (
          response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') ===
          '1'
        ) {
          const reauthorizeUrl = response.headers.get(
            'X-Shopify-API-Request-Failure-Reauthorize-Url'
          );
          if (reauthorizeUrl) {
            window.open(reauthorizeUrl, '_top');
          }

          return Promise.reject({
            statusCode: 403,
            errorCode: 403,
            message: 'Unauthorized.',
          } as ErrorResponse);
        }

        return response;
      })
    );
  }
}

export const GET = async <Res>(api: string, arg: BaseRequestParams) => {
  const { _non_serializable_domain, isBlob, ...params } = arg;
  const fetch = AuthenticatedFetch.get(_non_serializable_domain);

  try {
    const searchParams = getSearchParams(params);

    const response = await fetch(`${api}?${searchParams.toString()}`);

    if (isBlob) {
      return handleResponseBlob(response);
    }
    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const POST = async <Res>(api: string, arg: BaseRequestParams) => {
  const { _non_serializable_domain, ...body } = arg;
  const fetch = AuthenticatedFetch.get(_non_serializable_domain);

  try {
    const response = await fetch(api, {
      method: 'POST',
      body: JSON.stringify(body || {}),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const PATCH = async <Res>(api: string, arg: BaseRequestParams) => {
  const { _non_serializable_domain, ...body } = arg;
  const fetch = AuthenticatedFetch.get(_non_serializable_domain);

  try {
    const response = await fetch(api, {
      method: 'PATCH',
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const DELETE = async <Res>(api: string, arg: BaseRequestParams) => {
  const { _non_serializable_domain, ...body } = arg;
  const fetch = AuthenticatedFetch.get(_non_serializable_domain);

  try {
    const response = await fetch(api, {
      method: 'DELETE',
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const UPLOAD = async <Res>(
  api: string,
  arg: BaseRequestParams & { body?: FormData }
) => {
  const { _non_serializable_domain, body } = arg;
  const fetch = AuthenticatedFetch.get(_non_serializable_domain);
  try {
    const response = await fetch(api, {
      method: 'POST',
      body: (body as BodyInit) || null,
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

function getSearchParams(params: { [key: string]: unknown }): URLSearchParams {
  const searchParams = new URLSearchParams();
  if (params) {
    _.keys(params).forEach((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        value.forEach((v) => {
          searchParams.append(key, `${v}`);
        });
      } else if (value !== undefined) {
        searchParams.append(key, `${value}`);
      }
    });
  }
  return searchParams;
}

async function handleResponseBlob(response: Response) {
  try {
    const blob = await response.blob();

    return blob;
  } catch (error) {
    return handleError(error);
  }
}

async function handleResponseJson<Res>(response: Response) {
  try {
    const json = await response.json();

    const { statusCode, errorCode, message } = json;
    if (errorCode) {
      return Promise.reject({
        statusCode,
        errorCode,
        message,
      } as ErrorResponse);
    }

    return json as Res;
  } catch (error) {
    return handleError(error);
  }
}

function handleError(error: unknown) {
  if (isErrorResponse(error)) {
    return Promise.reject(error);
  }
  if (error instanceof Error) {
    const { message } = error;
    return Promise.reject({
      statusCode: -1,
      errorCode: -1,
      message,
    } as ErrorResponse);
  }
  if (typeof error === 'string') {
    return Promise.reject({
      statusCode: -1,
      errorCode: -1,
      message: error,
    } as ErrorResponse);
  }
  return Promise.reject(error);
}

export const appBaseQuery = (): BaseQueryFn<
  {
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'UPLOAD';
    url: string;
    arg: BaseRequestParams;
  },
  unknown,
  ErrorResponse
> => {
  return async ({ method, url, arg }, { dispatch }) => {
    try {
      switch (method) {
        case 'GET': {
          const data = await GET(url, arg);
          return { data };
        }
        case 'POST': {
          const data = await POST(url, arg);
          return { data };
        }
        case 'PATCH': {
          const data = await PATCH(url, arg);
          return { data };
        }
        case 'DELETE': {
          const data = await DELETE(url, arg);
          return { data };
        }
        case 'UPLOAD': {
          const data = await UPLOAD(url, arg);
          return { data };
        }
      }
    } catch (error) {
      const { statusCode, message } = error as ErrorResponse;
      //Ignore some error code here
      if (
        ![403, 429].includes(statusCode) &&
        !['BOOKING_DEPOSIT_IS_DONE', 'PREVIEW_VARIANT_NOT_FOUND'].includes(
          message
        )
      ) {
        dispatch(popupSlice.actions.showErrorPopup(message));
      }
      return { error: error as ErrorResponse };
    }
  };
};

export const appApi = createApi({
  baseQuery: appBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
    const { _non_serializable_domain, ...arg } =
      queryArgs as unknown as BaseRequestParams;

    return defaultSerializeQueryArgs({
      queryArgs: { ...arg },
      endpointDefinition,
      endpointName,
    });
  },
  endpoints: () => ({}),
  tagTypes: [
    'all',
    'shop',
    'services',
    // 'bookings',
    'staffs',
    'customers',
    'calendar',
    'analytics',
    'masterdata',
    'widgets',
    'subscriptions',
  ],
});
