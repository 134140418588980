import getConfig from 'next/config';

export enum AppFeatures {
  SINGLE_DAY_BOOKING = 1,
  CUSTOMER_QUESTION_FIELDS = 2,
  STAFF_CAPACITY = 3,
  BRANDING_REMOVAL = 4,
  BUNDLE_BOOKING = 5,
  BOOKING_SLOT_SECURE = 6,
  OUTLOOK_CALENDAR_INTEGRATION = 7,
  STAFF_SELECTION = 8,
  UNLIMITED_PRODUCTS = 9,
  UNLIMITED_NUMBER_OF_BOOKING = 10,
  MULTIPLE_STAFF_MEMBERS = 11,
  TIMEZONE_DETECTION = 12,
  EXPORT_BOOKINGS = 13,
  AUTOMATIC_EMAIL_NOTIFICATION = 14,
  CUSTOM_EMAIL_NOTIFICATION = 15,
  ADMIN_RESCHEDULING_CANCELLING = 16,
  BOOK_NOW_BUTTON_WIDGET = 17,
  WIDGET_CUSTOMIZATION = 18,
  GOOGLE_CALENDAR_INTEGRATION = 19,
  ZOOM_INTEGRATION = 20,
  DAY_BASED_BOOKING = 21,
  INSTANT_BOOKING = 22,
  CANCEL_BOOKING = 23,
  CUSTOMIZE_TIMESLOTS = 25,
  MULTIPLE_SLOTS = 26,
  // IGNORE_DST = 27,
  DAYLIGHT_SAVING = 27,
  RECURSIVE_BOOKING = 28,
  CONFIRMING_BOOKING = 29,
  POS = 30,
  DEPOSIT_BOOKING = 31,
  ADVANCE_STAFF_SETTING = 32,
  WAITLIST_BOOKING = 33,
  SERVICE_CAPACITY = 34,
  CUSTOMER_RESCHEDULE_CANCEL_BOOKINGS = 35,
  IMPORT_STAFF = 36,
  CONFIRMATION_CANCEL_EMAIL = 37,
  RESCHEDULE_FOLLOW_EMAIL = 38,
  ADVANCED_NOTIFICATION_SETTING = 39,
  MULTI_LANGUAGE_WIDGET = 40,
  BOOK_FROM_ANY_PAGE = 41,
  CUSTOM_EMAIL_SENDERS = 42,
  TIME_SLOT_SETTING = 43,
  ENABLE_TOTAL_CAPACITY = 50,
  ADVANCED_CAPACITY = 51,
  AFTER_DISCOUNT_SUBSCRIPTION = 52,
  STAFF_GROUP = 53,
  TEAMS_INTEGRATION = 58,
  BULK_IMPORT_PRODUCTS = 55,
  SMS_NOTIFICATION = 54,
  SERVICE_ADDON = 56,
}

export enum AppPlan {
  EARLY_BIRD = 1,
}

const { publicRuntimeConfig } = getConfig();
export const supportLanguages = [
  { locale: 'en', name: 'English', img: '/image/icons-flag/gb.svg' },
  publicRuntimeConfig.DEV_MENU_ENABLED && {
    locale: 'da',
    name: 'Dansk',
    img: '/image/icons-flag/dk.svg',
  },
  { locale: 'fr', name: 'Français', img: '/image/icons-flag/fr.svg' },
  { locale: 'de', name: 'Deutsch', img: '/image/icons-flag/de.svg' },
  { locale: 'it', name: 'Italiano', img: '/image/icons-flag/it.svg' },
  { locale: 'es', name: 'Español', img: '/image/icons-flag/es.svg' },
  {
    locale: 'nl',
    name: 'Nederlands',
    img: '/image/icons-flag/nl.svg',
  },
  {
    locale: 'pt-PT',
    name: 'Português (PT)',
    img: '/image/icons-flag/pt.svg',
  },
  {
    locale: 'pt-BR',
    name: 'Português (BR)',
    img: '/image/icons-flag/br.svg',
  },
  publicRuntimeConfig.DEV_MENU_ENABLED && {
    locale: 'fi',
    name: 'Suomalainen',
    img: '/image/icons-flag/fi.svg',
  },
  publicRuntimeConfig.DEV_MENU_ENABLED && {
    locale: 'sv',
    name: 'Svenska',
    img: '/image/icons-flag/se.svg',
  }, //Sweeden
  publicRuntimeConfig.DEV_MENU_ENABLED && {
    locale: 'cs',
    name: 'čeština',
    img: '/image/icons-flag/cz.svg',
  }, //Czech
  { locale: 'ja', name: '日本語', img: '/image/icons-flag/jp.svg' },
  { locale: 'zh-CN', name: '简体中文', img: '/image/icons-flag/cn.svg' }, //simplified
  { locale: 'zh-TW', name: '繁體中文', img: '/image/icons-flag/tw.svg' }, //traditional
  { locale: 'hi', name: 'भारतीय भाषा', img: '/image/icons-flag/in.svg' },

  { locale: 'vi', name: 'Tiếng Việt', img: '/image/icons-flag/vn.svg' },
].filter(Boolean);
export const supportLocales = supportLanguages.map((obj) => obj.locale);

export const ENABLE_DAYS_BOOKING = true; //publicRuntimeConfig.DEV_MENU_ENABLED;
export const ENABLE_INSTANT_BOOKING = publicRuntimeConfig.DEV_MENU_ENABLED;
export const ENABLE_PRESET_SLOT = true;
export const ENABLE_MULTIPLE_SLOT = publicRuntimeConfig.DEV_MENU_ENABLED;
export const ENABLE_DEPOSIT_PAYMENT = publicRuntimeConfig.DEV_MENU_ENABLED;
